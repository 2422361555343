.lottery_num {
    right: 67px;
    top: 11px;
  }
  .number {
    font-size: 21px !important;
  }
  .label {
    font-size: 11px !important;
  }
  .num_card {
    width: 25px !important;
  }
  .date {
    right: 107px;
    font-size: 16px;
  }
  
  @media screen and (max-width: 500px) {
    .lottery_num {
      right: 67px;
      top: 11px;
    }
    .number {
      font-size: 21px !important;
    }
    .label {
      font-size: 11px !important;
    }
    .num_card {
      width: 25px !important;
    }
    .date {
      right: 107px;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 480px) {
    .lottery_num {
      right: 64px;
      top: 12px;
    }
    .number {
      font-size: 21px !important;
    }
    .label {
      font-size: 11px !important;
    }
    .num_card {
      width: 26px !important;
    }
    .date {
      right: 104px;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 460px) {
    .lottery_num {
      right: 67px;
      top: 11px;
    }
    .number {
      font-size: 20px !important;
    }
    .label {
      font-size: 11px !important;
    }
    .num_card {
      width: 23px !important;
    }
    .date {
      right: 100px;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 440px) {
    .lottery_num {
      right: 62px;
      top: 10px;
    }
    .number {
      font-size: 20px !important;
    }
    .label {
      font-size: 11px !important;
    }
    .num_card {
      width: 22px !important;
    }
    .date {
      right: 95px;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 420px) {
    .lottery_num {
      right: 55px;
      top: 10px;
    }
    .number {
      font-size: 19px !important;
    }
    .label {
      font-size: 10px !important;
    }
    .num_card {
      width: 22px !important;
    }
    .date {
      right: 92px;
      font-size: 15px;
    }
  }
  @media screen and (max-width: 400px) {
    .lottery_num {
      right: 55px;
      top: 9px;
    }
    .number {
      font-size: 18px !important;
    }
    .label {
      font-size: 10px !important;
    }
    .num_card {
      width: 21px !important;
    }
    .date {
      right: 88px;
      font-size: 14px;
    }
  }
  @media screen and (max-width: 380px) {
    .lottery_num {
      right: 52px;
      top: 12px;
    }
    .number {
      font-size: 15px !important;
    }
    .label {
      font-size: 9px !important;
    }
    .num_card {
      width: 19px !important;
    }
    .date {
      right: 82px;
      font-size: 14px;
    }
  }
  @media screen and (max-width: 360px) {
    .lottery_num {
      right: 55px;
      top: 12px;
    }
    .number {
      font-size: 13px !important;
    }
    .label {
      font-size: 8px !important;
    }
    .num_card {
      width: 18px !important;
    }
    .date {
      right: 76px;
      font-size: 14px;
    }
  }
  @media screen and (max-width: 340px) {
    .lottery_num {
      right: 53px;
      top: 11px;
    }
    .number {
      font-size: 13px !important;
    }
    .label {
      font-size: 8px !important;
    }
    .num_card {
      width: 17px !important;
    }
    .date {
      right: 72px;
      font-size: 13px;
    }
  }
  @media screen and (max-width: 320px) {
    .lottery_num {
      right: 50px;
      top: 10px;
    }
    .number {
      font-size: 12px !important;
    }
    .label {
      font-size: 7px !important;
    }
    .num_card {
      width: 16px !important;
    }
    .date {
      right: 68px;
      font-size: 13px;
    }
  }
  @media screen and (max-width: 310px) {
    .lottery_num {
      right: 46px;
      top: 10px;
    }
    .number {
      font-size: 12px !important;
    }
    .label {
      font-size: 7px !important;
    }
    .num_card {
      width: 16px !important;
    }
    .date {
      right: 66px;
      font-size: 13px;
    }
  }
  @media screen and (max-width: 300px) {
    .lottery_num {
      right: 46px;
      top: 9px;
    }
    .number {
      font-size: 12px !important;
    }
    .label {
      font-size: 7px !important;
    }
    .num_card {
      width: 15px !important;
    }
    .date {
      right: 66px;
      font-size: 12px;
    }
  }
  @media screen and (max-width: 290px) {
    .lottery_num {
      right: 43px;
      top: 9px;
    }
    .number {
      font-size: 12px !important;
    }
    .label {
      font-size: 7px !important;
    }
    .num_card {
      width: 15px !important;
    }
    .date {
      right: 60px;
      font-size: 12px;
    }
  }
  @media screen and (max-width: 280px) {
    .lottery_num {
      right: 41px;
      top: 7.5px;
    }
    .number {
      font-size: 12px !important;
    }
    .label {
      font-size: 7px !important;
    }
    .num_card {
      width: 15px !important;
    }
    .date {
      right: 57px;
      font-size: 12px;
    }
  }
  
  /* Hide the default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 1.5rem;
    user-select: none;
    border: 1px solid blue;
    border-radius: 50%;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    --clr: #2563eb;
    position: relative;
    top: 0;
    left: 0;
    height: 0.75em;
    width: 0.75em;
    background-color: #fff;
    border-radius: 50%;
    transition: 300ms;
  }
  
  .container input:checked ~ .checkmark {
    background-color: var(--clr);
    border-radius: 50%;
    animation: pulse .35s ease-in-out;
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  .container .checkmark:after {
    left: 0.28em;
    top: 0.1em;
    width: 0.2em;
    height: 0.45em;
    border-width: 0 0.12em 0.12em 0;
    transform: rotate(45deg);
  }
  
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 #84abe5;
      rotate: 20deg;
    }
  
    50% {
      rotate: -20deg;
    }
  
    75% {
      box-shadow: 0 0 0 5px #84abe5;
    }
  
    100% {
      box-shadow: 0 0 0 7px #84abe5;
      rotate: 0;
    }
  }