
h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-top: 0 !important;
    margin-bottom: 0.5rem !important;
    font-weight: 600 !important;
    line-height: 1.2 !important;
    color: #1e2022 !important;
}
.h1, h1 {
    font-size: calc(1.26609375rem + .193125vw) !important;
}
.h2, h2 {
    font-size: calc(1.25625rem + .075vw) !important;
}
.h4, h4 {
    font-size: .984375rem !important;
}
.navbar-aside-toggler {
    background-color: rgb(1 1 60) !important;
}
.collapse {
    visibility: visible !important;
}
.btn-primary {
    background-color: #377dff !important;
}
.btn-dark {
    background-color: #132144 !important;
}

@tailwind base;
@tailwind components;
@tailwind utilities;


select.form-control{
    -webkit-appearance: menulist!important;
    -moz-appearance: menulist!important;
    -ms-appearance: menulist!important;
    -o-appearance: menulist!important;
    appearance: menulist!important;
};